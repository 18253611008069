import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import { Link } from "gatsby-theme-material-ui";

import Grid from "@material-ui/core/Grid";
import Container from "../components/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

import Layout from "../components/layout";
import Banner from "../components/Banner";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";
import useWpRestApi from "../hooks/useWpRestApi";

import i18nContext from "../i18n-context";
import { formatMomentDateByLang, mapMomentToPosts } from "../utils";

const BookPage = ({ data, pageContext }) => {
  const classes = useStyles();
  const url = useUrl();
  const lang = useLanguage();
  const i18n = useContext(i18nContext)[lang];
  const isPhone = useMediaQuery(useTheme().breakpoints.down("xs"));
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  const bookCategories = useWpRestApi("/related-books");

  const { title, excerpt, translated } = pageContext;
  const sourceUrl = pageContext.featuredImage?.node?.sourceUrl || "";
  const ogDescription = excerpt ? parse(excerpt)[0]?.props?.children : "";

  useEffect(() => {
    let elemId = window.location.href.substr(
      window.location.href.indexOf("#") + 1
    );
    if (lang !== "en") elemId = decodeURIComponent(elemId);
    const elem = document.getElementById(elemId);
    const appbar = document.getElementById("appbar");
    if (elem && appbar) {
      const appbarHeight = !isPhone
        ? appbar.offsetHeight + 15
        : appbar.offsetHeight;
      const elemPos = elem.getBoundingClientRect().top;

      const bodySize = document.body || document.getElementsByTagName("body")[0];
      const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || bodySize.scrollTop);

      window.scrollTo({
        top: elemPos - appbarHeight + scrollTop,
        behavior: "smooth",
      });
    }
  }, [bookCategories]);

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={sourceUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content={sourceUrl} />
      </Helmet>

      <Banner title={title} bannerUrl={sourceUrl} />
      <Container>
        <Typography
          variant="body1"
          className={classes.description}
          component="div"
        >
          {parse(excerpt)}
        </Typography>
        {bookCategories.map((cat, index) => {
          const { name: catName, posts: bookList, slug: catSlug } = cat;
          return (
            Array.isArray(bookList) &&
            bookList.length > 0 && (
              <div
                aria-label="book-category"
                key={`books-category-${index}`}
                id={decodeURI(catSlug)}
              >
                <div
                  aria-label="book-category-heading"
                  className={classes.headingRoot}
                >
                  <Typography
                    variant="h4"
                    className={classes.headingText}
                    align={isDesktop ? "left" : "center"}
                  >
                    {catName}
                  </Typography>
                  <Divider className={classes.headingDivider} />
                </div>

                <Grid
                  container
                  spacing={5}
                  aria-label="book-list"
                  className={classes.bookList}
                >
                  {mapMomentToPosts(bookList, "publication_date")
                    .map((book) => ({
                      ...book,
                      publication_date: formatMomentDateByLang(
                        book.publication_date,
                        lang
                      ),
                    }))
                    .map((book) => {
                      const {
                        author,
                        title: bookTitle,
                        uri: bookUri,
                        publication_date: date,
                        thumbnail: bookThumbnailSrc,
                      } = book;

                      return (
                        <Grid item lg={6} xs={12} key={bookTitle}>
                          <Link underline="none" to={bookUri}>
                            <Grid
                              container
                              spacing={2}
                              className={classes.bookItemContainer}
                            >
                              <Grid item xs={3}>
                                <img
                                  src={bookThumbnailSrc}
                                  alt=""
                                  className={classes.thumbnail}
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <Typography
                                  variant="h5"
                                  className={classes.bookTitle}
                                >
                                  {bookTitle}
                                </Typography>
                                <Typography variant="body2">
                                  {author}
                                </Typography>
                                <Typography variant="body2">{date}</Typography>
                              </Grid>
                            </Grid>
                          </Link>
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            )
          );
        })}
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },

  headingRoot: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(4),
    },
  },
  headingText: {
    fontFamily: "Open Sans",
  },
  headingDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(8)}px`,
    },
  },
  bookList: {
    paddingBottom: theme.spacing(8),
    marginBottom: 20,
  },
  thumbnail: {
    width: "100%",
    // [theme.breakpoints.up("1440")]: {
    //   maxHeight: 185
    // },
  },
  bookItemContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    minHeight: 220,
    [theme.breakpoints.up("1440")]: {
      height: 275,
    },
  },

  bookTitle: {
    marginBottom: theme.spacing(1),
  },
}));

export const query = graphql`
  query getBooksQuery($locale: String) {
    allWpBooksCategory(filter: { locale: { locale: { eq: $locale } } }) {
      nodes {
        name
        slug
        books {
          nodes {
            title
            uri
            contentGenericGroup {
              publicationDate
              author
              thumbnail {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

export default BookPage;
